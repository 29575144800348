import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './LoadingIndicator.module.scss'
import { ReactComponent as LogoSvg } from './images/logo.svg'

export default function LoadingIndicator({ className }) {
  return (
    <div className={classNames('LoadingIndicator', styles.this, className)}>
      <LogoSvg />
      <div className={styles.spinner}>
        <div className={styles.bounce1}></div>
        <div className={styles.bounce2}></div>
        <div className={styles.bounce3}></div>
      </div>
    </div>
  )
}

LoadingIndicator.propTypes = {
  className: PropTypes.string,
}
