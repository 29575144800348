import { navigate } from 'gatsby'
import React from 'react'

import LoadingIndicator from '@fundrocket/common-web/components/LoadingIndicatorWomply'

import paths from 'constants/paths'

export default function Bills() {
  if (typeof window !== 'undefined') {
    navigate(paths.SIGN_IN)
  }

  return <LoadingIndicator />
}
